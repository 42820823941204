<template>
  <div class="sys-user list-page">
    <div class="search-box">
      <div class="title">
        <h1>搜索</h1>
        <p>Search Find</p>
      </div>
      <el-form :model="formData" ref="formRef" class="body el-row">
        <el-col :span="8">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="账号" prop="account">
            <el-input v-model="formData.account" placeholder="请输入账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" class="search-btn-box">
          <el-button class="reset" @click="handleReset('formRef')">重置</el-button>
          <el-button class="search active" @click="handleSearch()">搜索</el-button>
        </el-col>
      </el-form>
    </div>
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>查询结果</h1>
          <p>search result</p>
        </div>
        <div class="global-operate-btn-box">
          <el-button class="active" @click="handleAdd"><img src="@/assets/imgs/add-user.png" />新增用户</el-button>
        </div>
      </div>
      <el-table 
        :data="tableData" 
        ref="multipleTable" 
        row-key="id"
        @select="handleSelect" 
        @select-all="handleSelectAll">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="account" label="账号"></el-table-column>
        <el-table-column prop="workStationName" label="所属工段"></el-table-column>
        <el-table-column prop="roles" label="角色权限" :formatter="formatterRoles"></el-table-column>
        <el-table-column label="操作" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleEdit(row)">修改</el-button>
            <el-button @click="handleDelete(row)" class="del">删除</el-button>
            <el-button @click="handleEditPwd(row)">修改密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 修改弹框 -->
    <el-dialog v-model="editDialogVisible" width="600px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}</h1>
        <p>{{curEditLabel.en}}</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="edit-form" :rules="editFormRules">
        <el-form-item label="账号" prop="account">
          <el-input v-model="editFormData.account" placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="curEditLabel.en == 'Add'" label="密码" prop="authentication">
          <el-input v-model="editFormData.authentication" show-password placeholder="请输入" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editFormData.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="所属工段" prop="workStation">
          <el-select v-model="editFormData.workStation" clearable placeholder="请选择">
            <el-option
              v-for="item in workStationList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="虚拟工段" prop="virtualWorkStationList" >
          <el-select v-model="editFormData.virtualWorkStationList" clearable placeholder="请选择" multiple>
            <el-option
                v-for="item in virtualWorkStationList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色权限" prop="roles">
          <el-select v-model="editFormData.roles" placeholder="请选择" multiple>
            <el-option
              v-for="item in rolesList"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">确 认</el-button>
      </template>
    </el-dialog>

    <!-- 修改密码弹框 -->
    <el-dialog v-model="editPwdDialogVisible" width="600px" @close="cancelEdit('editPwdFormRef')">
      <template #title>
        <h1>修改密码</h1>
        <p>Edit password</p>
      </template>
      <el-form :model="editPwdFormData" ref="editPwdFormRef" class="edit-form" :rules="editPwdFormRules">
        <el-form-item label="密码" prop="authentication">
          <el-input v-model="editPwdFormData.authentication" show-password placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEditPwd('editPwdFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEditPwd('editPwdFormRef')">确 认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { sys, common } from '@/api'
import { encode } from 'js-base64'

export default {
  name: 'SysUser',
  data() {
    return {
      formData: { // 查询表单
        account: '',
        name: '',
      },
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },

      curEditLabel: {
        zh: '',
        en: '',
      },
      editDialogVisible: false, // 修改弹框visible
      initEditFormData: { 
        account: '',
        authentication: '',
        name: '',
        workStation: '',
        roles: [],
        virtualWorkStationList: []
      },
      editFormData: {...this.initEditFormData}, // 修改表单
      editFormRules: { // 修改表单的校验
        account: { required: true, message: '请输入', trigger: 'blur' },
        authentication: { required: true, message: '请输入', trigger: 'blur' },
        name: { required: true, message: '请输入', trigger: 'blur' },
      },
      workStationList: [], // 工段列表
      virtualWorkStationList: [],
      rolesList: [], // 角色列表
      editPwdDialogVisible: false, // 修改密码弹框visible
      editPwdFormData: { // 修改密码表单
        authentication: '',
      },
      editPwdFormRules: { // 修改密码表单的校验
        authentication: { required: true, message: '请输入', trigger: 'blur' },
      },
    }
  },
  created() {
    this.loadTableData()
  },
  methods: {
    // 获取字典数据
    async getDict() {
      let types = 'WORK_STATION'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.workStationList = data.WORK_STATION || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取字典数据
    async getVirtualDictWorkStation() {
      const {code, msg, data = {}} = await common.queryVirtualDictWorkStation()
      if (code === 0) {
        this.virtualWorkStationList = data || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取角色列表
    async getRolesList() {
      const {code, msg, data} = await sys.queryRoles()
      if (code === 0) {
        this.rolesList = data || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取列表数据
    async loadTableData() {
      let params = {
        ...this.formData,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await sys.getUserList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 重置
    handleReset(formName) {
      this.$refs[formName].resetFields()
      this.pagination.currentPage = 1
      this.loadTableData()
    },
    // 格式化角色权限
    formatterRoles(row, column, cellValue) {
      if(Array.isArray(cellValue) && cellValue.length > 0) {
        let result = cellValue.map(item => {
          return item.name
        })
        return result.toString()
      }
      return ''
    },
    // 点击新增
    handleAdd() {
      this.getDict()
      this.getVirtualDictWorkStation();
      this.getRolesList()
      this.editFormData = this.initEditFormData

      this.curEditLabel.zh = '新增'
      this.curEditLabel.en = 'Add'
      this.editDialogVisible = true
    },
    // 点击修改
    handleEdit(row) {
      this.getDict()
      this.getVirtualDictWorkStation();
      this.getRolesList()
      this.editFormData = {
        ...row,
        roles: row.roles ? row.roles.map(item => item.code) : []
      }
      console.log(this.editFormData)
      this.curEditLabel.zh = '修改'
      this.curEditLabel.en = 'Edit'
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let roles = this.editFormData.roles.map(item => {
            return {'code': item}
          })
          
          let params = {
            ...this.editFormData,
            authentication: encode(this.editFormData.authentication),
            roles
          }

          const {code, msg} = await sys.saveUser(params)
          if(code === 0) {
            this.editDialogVisible = false
            this.$message.success(`${this.curEditLabel.zh}成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
    // 点击删除
    handleDelete({id, longId}) {
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.delUser({id, longId})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
    // 修改密码
    handleEditPwd(row) {
      this.editPwdFormData = {...row}
      this.editPwdDialogVisible = true
    },
    // 确认修改密码
    confirmEditPwd(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {
            ...this.editPwdFormData,
            authentication: encode(this.editPwdFormData.authentication),
          }

          const {code, msg} = await sys.saveUser(params)
          if(code === 0) {
            this.editPwdDialogVisible = false
            this.$message.success(`操作成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改密码
    cancelEditPwd(formName) {
      this.$refs[formName].resetFields()
      this.editPwdDialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.sys-user {
  .edit-form {
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;
    padding: 30px 30px 10px;
    .el-form-item {
      ::v-deep .el-form-item__label{
        width: 70px;
        color: #B4B4C5;
      }
      ::v-deep .el-form-item__content{
        .el-select {
          width: 100%;
          .el-select__tags .el-tag {
            background-color: #2e2e41;
            .el-icon-close {
              background-color: #1a1a25;
            }
          }
        }
      }
    }
  }
}
</style>